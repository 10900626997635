<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Property' : 'Add New Property'" />
        <div>
          <md-button :to="{ name: 'Estate Management' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateType">
          <md-field :class="getValidationClass('title')">
            <label>Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('description')">
            <label>Description</label>
            <md-textarea v-model="form.description" required></md-textarea>
            <span class="md-error" v-if="!$v.form.description.required"
              >Description is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('price')">
            <label>Price</label>
            <md-input type="number" v-model="form.price" required></md-input>
            <span class="md-error" v-if="!$v.form.price.required"
              >Price is required</span
            >
          </md-field>

          <div data-v-de04d2778="" class="md-field md-theme-default">
            <!-- <label data-v-de04d2778="" for="search">Set property location</label -->
            <input
              data-v-de04d2778=""
              type="search"
              id="search"
              required="required"
              class="md-input"
              placeholder="Set property location"
              ref="autocomplete"
            />
          </div>

          <div id="infowindow-content">
            <img src width="16" height="16" id="place-icon" />
            <span id="place-name" class="title"></span>
            <br />
            <span id="place-address"></span>
          </div>
          <div id="map"></div>

          <div class="row" style="width: 100%">
            <div class="col-6">
              <md-field :class="getValidationClass('location')">
                <label>Location</label>
                <md-input v-model="form.location" required></md-input>
                <span class="md-error" v-if="!$v.form.location.required"
                  >Location is required</span
                >
              </md-field>
            </div>
            <div class="col-6">
              <md-field v-if="metadata" :class="getValidationClass('state')">
                <label for="state">State</label>
                <md-select v-model="form.state_id" name="state" id="state">
                  <md-option value="" disabled>Select Property Type</md-option>
                  <md-option
                    v-for="(state, id) in metadata.states"
                    :key="id"
                    :value="id"
                    >{{ state }}</md-option
                  >
                </md-select>
                <span class="md-error" v-if="!$v.form.state_id.required"
                  >State is required</span
                >
              </md-field>
            </div>
          </div>

          <md-field :class="getValidationClass('video')">
            <label>Youtube Video ID</label>
            <md-input v-model="form.video"></md-input>
          </md-field>

          <md-field v-if="metadata" :class="getValidationClass('type_id')">
            <label for="type_id">Property Type</label>
            <md-select
              @md-selected="setFeatures()"
              v-model="form.type_id"
              name="type_id"
              id="type_id"
            >
              <md-option value="" disabled>Select Property Type</md-option>
              <md-option
                v-for="(type, id) in metadata.types"
                :key="id"
                :value="id"
                >{{ type }}</md-option
              >
            </md-select>
            <span class="md-error" v-if="!$v.form.type_id.required"
              >Property Type is required</span
            >
          </md-field>

          <div class="field mt-3" style="min-width: 50%">
            <label>Features</label>
            <table id="feature_table" class="table bordered p-5 bg-light">
              <thead>
                <tr>
                  <th>feature</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(feature, id) in form.featureData" :key="id">
                  <td>
                    <label>
                      <strong>{{ feature.title }}</strong>
                    </label>
                  </td>
                  <td>
                    <textarea
                      :name="'_selectedFeatures_' + feature.key + '_value'"
                      v-model="form.featureData[id].value"
                      class="form-control bg-white"
                      rows="1"
                    ></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />

          <md-field
            v-if="metadata"
            :class="getValidationClass('approval_status')"
          >
            <label for="approval_status">Approval Status</label>
            <md-select
              v-model="form.approval_status"
              name="approval_status"
              id="approval_status"
            >
              <md-option
                v-for="(status, id) in metadata.approval_statuses"
                :key="id"
                :value="id"
                >{{ status }}</md-option
              >
            </md-select>
          </md-field>

          <mdc-button type="submit" :loading="loading" :disabled="loading">{{
            id ? "Update Property" : "Create Property"
          }}</mdc-button>
          <br />
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("realtor");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      location: "",
      featureData: [],
    },
    loading: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
      },
      description: {
        required,
      },
      price: {
        required,
      },
      type_id: {
        required,
      },
      state_id: {
        required,
      },
      location: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createProperty", "updateProperty", "getPropFormData"]),
    async validateType() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.selectedfeatures = JSON.stringify(this.form.featureData);
        delete data.featureData;
        try {
          this.loading = true;
          let call = this.id
            ? this.updateProperty(data)
            : this.createProperty(data);

          let msg = this.id ? "Property Updated" : "Property Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Estate Management" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      } else {
        alert();
      }
    },
    setFeatures() {
      const _tranformedFeatures = [];
      let _this = this;
      let featureDom = this.metadata.feature_categories;
      featureDom.forEach(function (category) {
        if (category.type_id == _this.form.type_id) {
          _tranformedFeatures.push({
            key: category.id,
            value: "",
            title: category.title,
          });
        }
      });

      this.form.featureData = _tranformedFeatures;
    },
    async getData() {
      const data = await this.getPropFormData();
      this.metadata = data;
    },
    initMap() {
      let _vmThis = this;
      var lat_lng = "9.146450030901937,7.403863281250063";

      const latlng = lat_lng.split(",");
      var myLatlng = {
        lat: Number.parseFloat(latlng[0]),
        lng: Number.parseFloat(latlng[1]),
      };

      var geocoder = new google.maps.Geocoder();
      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 7,
        center: myLatlng,
      });

      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        {
          types: ["geocode"],
          componentRestrictions: { country: "ng" },
          setFields: ["address_components", "geometry", "icon", "name"],
        }
      );

      var infowindow = new google.maps.InfoWindow();
      var infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);

      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        draggable: true,
        title: "Set Property Location",
      });

      //DRAGEND FEATURE

      marker.addListener("dragend", function () {
        map.setCenter({
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
        });
        _vmThis.form.lat_lng = `${marker.getPosition().lat()},${marker
          .getPosition()
          .lng()}`;
        geocoder.geocode(
          { location: marker.getPosition() },
          function (results, status) {
            if (status === "OK") {
              if (results[0]) {
                marker.setTitle(results[0].formatted_address);

                let addresses = results[0];
                let address = [
                  (addresses.address_components &&
                    addresses.address_components[0].long_name) ||
                    "",
                  (addresses.address_components &&
                    addresses.address_components[1].long_name) ||
                    "",
                  (addresses.address_components &&
                    addresses.address_components[2].long_name) ||
                    "",
                ].join(" ");

                _vmThis.form.location = results[0].formatted_address;

                let _states = Object.values(_vmThis.metadata.states);

                for (
                  let index = 0;
                  index < addresses.address_components.length;
                  index++
                ) {
                  if (
                    addresses.address_components[index].types[0] ==
                    "administrative_area_level_1"
                  ) {
                    //this is the object you are looking for State
                    var marker_state =
                      addresses.address_components[index].long_name;
                    const isCapitalTeritory =
                      marker_state === "Federal Capital Territory"
                        ? true
                        : false;
                    _states.filter(function (state, key) {
                      if (state == marker_state) {
                        _vmThis.form.state_id = key + 1;
                      }

                      if (state == "FCT" && isCapitalTeritory) {
                        _vmThis.form.state_id = key + 1;
                      }
                    });
                  }
                }
              } else {
                window.alert("No results found");
              }
            } else {
              window.alert("Geocoder failed due to: " + status);
            }
          }
        );
      });

      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        infowindow.close();
        marker.setVisible(false);

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17); // Why 17? Because it looks good.
        }

        marker.setPosition(place.geometry.location);
        marker.setVisible(true);

        var address = "";
        if (place.address_components) {
          address = [
            (place.address_components[0] &&
              place.address_components[0].short_name) ||
              "",
            (place.address_components[1] &&
              place.address_components[1].short_name) ||
              "",
            (place.address_components[2] &&
              place.address_components[2].short_name) ||
              "",
          ].join(" ");

          //MERGE TO A SINGLE FUNCTION --REMOVE DUPLICATE SON
          let _states = Object.values(_vmThis.metadata.states);

          for (
            let index = 0;
            index < place.address_components.length;
            index++
          ) {
            if (
              place.address_components[index].types[0] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for State
              var marker_state = place.address_components[index].long_name;
              const isCapitalTeritory =
                marker_state === "Federal Capital Territory" ? true : false;
              _states.filter(function (state, key) {
                if (state == marker_state) {
                  _vmThis.form.state_id = key;
                }

                if (state == "FCT" && isCapitalTeritory) {
                  _vmThis.form.state_id = key;
                }
              });
            }
          }
        }

        infowindowContent.children["place-icon"].src = place.icon;
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent = address;
        _vmThis.form.location = address;
        _vmThis.form.lat_lng = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
        infowindow.open(map, marker);
      });
    },
  },
  mounted() {
    this.initMap();
    this.getData();
    if (this.id) {
      this.getType(this.id).then((type) => {
        this.form = {
          title: type.title,
          id: type.id,
        };
      });
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-Types {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
